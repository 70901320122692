import * as React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const dateFormatter = (date) => new Date(date).toLocaleDateString();

const LineChart = ({
  data = [],
  title = '',
  unit = null
}) => {
  if (!data.length > 0) return false;

  // Data tiene que ser unarray de objetos con 2 keys cada uno, correspondientes a los ejes X e Y del gráfico
  const dataKeys = Object.keys(data[0]);

  // Dominio
  const firstDay = data[0][dataKeys[0]];
  const lastDay = data[data.length - 1][dataKeys[0]];

  return dataKeys.length > 1 ? <Card>
    <CardHeader title={title} />
    <CardContent>
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3D9DBF" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#255E73" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey={dataKeys[0]}
              name="Date"
              type="number"
              scale="time"
              domain={[firstDay.getTime(), lastDay.getTime()]}
              tickFormatter={dateFormatter}
            />
            <YAxis dataKey={dataKeys[1]} name="Revenue" unit={unit} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              formatter={(value) => value}
              labelFormatter={(label) => dateFormatter(label)}
            />
            <Area
              type="monotone"
              dataKey={dataKeys[1]}
              stroke="#3D9DBF"
              strokeWidth={2}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </CardContent>
  </Card> : null
};

export default LineChart;
